<template>
  <div>
    <el-table
      ref="tableData"
      v-loading="tableLoading"
      :data="tableData"
      :header-cell-style="{ background: '#fafafa' }"
      tooltip-effect="dark"
      max-height="400px"
    >
      <el-table-column :label="$t('page.No')" type="index" width="70" align="center" />
      <el-table-column label="处置申请单号" min-width="140">
        <template slot-scope="scope">
          <router-link
            :to="{
              path: 'details',
              query: {disposeCode:scope.row.disposeCode},
              append:true
            }"
            style="color: #1890ff"
          >{{
            scope.row.disposeCode
          }}</router-link>
        </template>
      </el-table-column>
      <el-table-column prop="totalQuantity" label="处置总数量" />
      <el-table-column prop="forecastAmount" label="预估处置金额" />
      <el-table-column prop="currency" label="币种" />
      <el-table-column prop="releasePairs" label="申请人" />
      <el-table-column prop="applyDate" label="申请日期" />
      <el-table-column prop="approveDate" label="审批日期" />
      <el-table-column prop="finishDate" label="处置完成日期" />
      <el-table-column prop="approveStatus" label="状态">
        <template slot-scope="scope">
          <span v-if="scope.row.approveStatus==1">待审批</span>
          <span v-if="scope.row.approveStatus==2">审批通过</span>
          <span v-if="scope.row.approveStatus==3">审批驳回</span>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作">
        <template slot-scope="scope">
          <el-button type="text" @click="view(scope.row)">查看</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  props: {
    tableData: {
      type: Array,
      default() {
        return []
      }
    },
    tableLoading: {
      type: Boolean,
      default: false
    },
    flag: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  mounted() {

  },
  methods: {
    modify({ applyId, historyId }) {
      this.$router.push({
        path: 'add',
        append: true,
        query: { applyId, historyId }
      })
    },
    view(row) {
      this.$router.push({
        path: 'details',
        append: true,
        query: { 'disposeCode': row.disposeCode }
      })
    }
  }
}
</script>

<style scoped lang="scss">

</style>
