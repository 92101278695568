<template>
  <!-- 处置申请 -->
  <div class="app-container">
    <el-form ref="form" :model="form" label-width="120px" style="display: flex;">
      <div class="el-lt" style="width:85%;">
        <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
          <el-col :span="8">
            <el-form-item label="处置申请单号">
              <el-input v-model="form.disposeCode" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="申请人">
              <el-input v-model="form.proposerName" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <!-- <el-form-item label="状态">
              <el-select v-model="form.approveStatus" :placeholder="$t('page.selectPlaceholder')">
                <el-option
                  :value="1"
                  label="待审核"
                />
                <el-option
                  :value="2"
                  label="通过"
                />
                <el-option
                  :value="3"
                  label="驳回"
                />
              </el-select>
            </el-form-item> -->
          </el-col>
          <el-col :span="16">
            <el-form-item label="申请日期">
              <el-date-picker
                v-model="form.date"
                type="daterange"
                value-format="yyyy-MM-dd "
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
              />
              <!-- <el-date-picker
                v-model="form.date"
                type="datetimerange"
                value-format="yyyy-MM-dd HH:mm:ss"
                range-separator="~"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :unlink-panels="true"
                :default-time="['00:00:00', '23:59:59']"
              /> -->
            </el-form-item>
          </el-col>

        </el-row>
        <el-row class="mb-2">
          <el-button type="primary"><router-link to="added" append>申请处置</router-link></el-button>
        </el-row>
      </div>
      <el-row class="el-rt" style="width:25%;">
        <el-col class="row-center">
          <el-button type="primary" @click="queryClick()">{{ $t('page.search') }}</el-button>
          <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
          <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
        </el-col>
      </el-row>

    </el-form>
    <el-row>
      <el-tabs v-model="form.approveStatus" type="card" @tab-click="queryClick(1)">
        <el-tab-pane label="待审批" name="1">
          <ApplyTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
        <el-tab-pane label="审批驳回" name="3">
          <ApplyTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
        <el-tab-pane label="审批通过" name="2">
          <ApplyTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
        <el-tab-pane label="全部" name=" ">
          <ApplyTable :table-data="tableData" :table-loading="tableLoading" />
        </el-tab-pane>
      </el-tabs>
    </el-row>

  </div>
</template>
<script>
import ApplyTable from './components/applyTable'

import { pageDisposeApply } from '@/api/disposal-apply'
import { Mixin } from '@/mixin/mixin.js'
export default {
  components: { ApplyTable },
  mixins: [Mixin],
  data() {
    return {
      form: {
        approveStatus: ' ',
        disposeCode: '',
        proposerName: '',
        date: [],
        status: '1'
      },
      pager: {
        size: 20,
        current: 1,
        total: 0
      },
      showRow: false, // 切换显示input
      tableLoading: false,
      tableData: []
    }
  },
  computed: {
    queryParams() {
      const [applyStartDate = '', applyEndDate = ''] = this.form.date || []
      const { approveStatus, disposeCode, proposerName } = this.form
      return Object.assign({}, this.pager, { applyStartDate, applyEndDate, approveStatus, disposeCode, proposerName })
    }
  },

  mounted() {
    this._pageDisposeApply(this.queryParams)
  },
  methods: {

    queryClick() { // 点击查询获取信息
      this.pager.current = 1
      this._pageDisposeApply(this.queryParams)
    },
    resetClick(val) { // 点击重置清空文本框信息
      this.form = {
        approveStatus: ' ',
        disposeCode: '',
        proposerName: '',
        date: [],
        status: '1'
      }
      this._pageDisposeApply(this.queryParams)
    },
    showRowClick() { // 点击展开控制文本框的显示隐藏
      this.showRow = !this.showRow
    },
    async _pageDisposeApply(params) {
      try {
        this.loading = true
        const { datas } = await pageDisposeApply(params)
        if (datas) {
          this.pager = datas.pager
          this.tableData = datas.records
        }
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

